<form [formGroup]="travelAgeRatedForm" (ngSubmit)="onSubmit()" class="form" novalidate>
  <div class="form-row">
    <div class="col-md-4">
      <div class="form-group">

        <label for="state">State of Residence</label>
        <select id="state" class="form-control form-control-sm" formControlName="state">
          <option value="" selected>Choose a state</option>
          <option *ngFor="let state of states" value="{{state.code}}">{{state.code}}-{{state.desc}}</option>
        </select>
        <app-input-validation [inputFormControl]="state"></app-input-validation>

      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="totalTripCost" placement="top" ngbTooltip="{{tripCostTooltipMessage}}">Total Trip Cost
          <span class="fa fa-question-circle"></span>
        </label>

        <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
          <input type="text" id="totalTripCost" class="form-control form-control-sm" formControlName="totalTripCost">
        </div>
        <app-input-validation [inputFormControl]="totalTripCost">
          <div class="validation-error__message" *ngIf="totalTripCost.errors?.pattern">Invalid format. Entry must be a number with up to two (2) decimal places.</div>
        </app-input-validation>
      </div>
    </div>
    <div *ngIf="destinationEnum !== destEnum.None" [ngSwitch]="destinationEnum" class="col-md-4">
      <div *ngSwitchCase="destEnum.Full" class="form-group">
        <label for="destination">Destination
          <small class="text-muted">
            Optional
          </small>
        </label>
        <ng-select class="custom" (change)="destSelected($event)" [items]="destinations | async" bindValue="desc" bindLabel="desc"
          formControlName="destination"></ng-select>
        <app-input-validation [inputFormControl]="destination"></app-input-validation>
      </div>

      <div *ngSwitchCase="destEnum.IntlDom" class="form-group">
        <label for="destination">Destination
          <small class="text-muted">
            Optional
          </small>
        </label>
        <select id="destination" class="form-control form-control-sm" formControlName="destination">
          <option value="domestic">Domestic</option>
          <option value="international">International</option>
        </select>
        <app-input-validation [inputFormControl]="destination"></app-input-validation>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-5 departure-return-date">
      <div class="form-group">
        <label for="departureDate">Departure - Return Date</label>
        <app-calendar>
          <p-calendar #rangeDatePicker class="custom" data-test-id="fromToDate" formControlName="departureReturnDate" selectionMode="range"
            [readonlyInput]="true" [minDate]="minDateTomorrow" [numberOfMonths]="displayMonths" [showIcon]="true" icon="pi pi-calendar" [showOtherMonths]="false"
            (onClose)="onClose()" (onSelect)="onSelect()">
              <ng-template pTemplate="date" let-date>
                <span id= "{{date.month}}{{date.day}}" >{{date.day}}</span>
              </ng-template>
            <p-footer>
              <button type="button" mat-button class="link" (click)="onClose()">Done</button>
            </p-footer>
          </p-calendar>
        </app-calendar>
        <app-input-validation [inputFormControl]="departureReturnDate">
          <div class="validation-error__message" *ngIf="departureReturnDate.invalid">This field is required.</div>
        </app-input-validation>
      </div>
    </div>
    <div class="col-md-4 initial-deposit-date-wrapper">
      <div class="form-group">
        <label for="depositDate">Initial Deposit Date</label>
        <app-calendar>
          <p-calendar class="custom initial-deposit-calendar" formControlName="departureDate" placeholder="MM/DD/YYYY" [maxDate]="maxDate"
            formControlName="depositDate" [readonlyInput]="true" [showIcon]="true" icon="pi pi-calendar">
          </p-calendar>
        </app-calendar>
        <app-input-validation [inputFormControl]="depositDate">
          <div class="validation-error__message" *ngIf="depositDate.invalid">This field is required.</div>
        </app-input-validation>
      </div>
    </div>

      <!-- Popover content -->
      <div class="col-md-3 age-dob-container">
        <div class="form-group">
          <ng-template #popContent>

              <div class="form-check form-check-inline">
                  <label class="font-normal" for="radAge">
                    <input id="radAge" type="radio" [value]="ageOrDobEnum.Age" (change)="ageDobSwitch()" formControlName="travelerAgeSelection"> Age
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="font-normal" for="radDateOfBirth">
                    <input id="radDateOfBirth" type="radio" [value]="ageOrDobEnum.Dob" (change)="ageDobSwitch()" formControlName="travelerAgeSelection"> Date of Birth
                  </label>
                </div>

            <div>
              <div class="form-group" [ngSwitch]="travelerAgeSelection.value">
                <div class="form-row traveler-list" formArrayName="ageArray" *ngSwitchCase="ageOrDobEnum.Age">
                  <div class="col-age col-md-12" *ngFor="let control of ageArray.controls; let i = index; let first = first">
                    <div class="form-group">
                      <div [formGroup]="control" class="input-group input-group-sm">
                        <div class="form-label col-8">
                          <label *ngIf="first; else secondary_traveler">Primary Traveler's Age</label>
                          <ng-template #secondary_traveler><label>Additional Traveler</label></ng-template>
                        </div>
                        <input type="text" class="form-control age-field col-2" formControlName="age">
                        <span *ngIf="i >= NUMBER_AGE_BLOCKS" class="input-group-addon col-2" (click)="removeAge(i)" type="button">
                          <i class="fa fa-trash"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row traveler-list" formArrayName="ageArray" *ngSwitchCase="ageOrDobEnum.Dob">
                  <div class="col-md-12" *ngFor="let control of ageArray.controls; let i = index; let first = first">
                    <div class="form-group">
                      <div [formGroup]="control" class="input-group input-group-sm">
                        <div class="form-label col-5">
                          <label *ngIf="first; else secondary_traveler">Primary Traveler's Date of Birth</label>
                          <ng-template #secondary_traveler><label>Additional Traveler</label></ng-template>
                        </div>
                        <app-date-picker class="col-6"  [isDateOfBirth]="true" formControlName="age" [maxDate]="today"></app-date-picker>
                        <span *ngIf="i >= NUMBER_AGE_BLOCKS" class="input-group-addon col-1" (click)="removeAge(i)">
                          <i class="fa fa-trash"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="button" *ngIf="canAddTravelers()" mat-button class="link add-button" (click)="addAge()">
                  <span class="fa fa-plus"></span> Add Traveler</button>
              </div>
            </div>
            <button type="button" mat-button class="link done" (click)="onClosePopover($event)">Done</button>
          </ng-template>
          <label>Traveler Ages</label>
          <app-calendar>
            <input type="text" class="form-control col-12 popover-input" placement="bottom" testId="travelerAgesInput"
            [ngbPopover]="popContent"
            #p="ngbPopover"
            [autoClose]="false"
            appClosePopoverOnClickOutside
            [value]="displayAgeDob(ageArray)"
            readonly="readonly">
          </app-calendar>
          <app-input-validation [inputFormControl]="ageArray.controls[0]['controls']['age']"></app-input-validation>
        </div>
      </div>

    <!-- /Popover Content  -->

  </div>



  <app-form-validation [errorMessages]="errors"></app-form-validation>
  <div class="form-row" *ngIf="showButton">
    <div class="col">
      <app-dynamic-button [compId]="componentId + 'age-rated-btn'" type="submit" class="pull-right" [disabled]="buttonDisabled" [buttonText]="quoteButtonText" [buttonHoverColor]="quoteButtonHoverColor"
        [buttonBackgroundColor]="quoteButtonColor"></app-dynamic-button>
    </div>
  </div>

</form>
